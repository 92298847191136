import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { searchItems } from "../../actions/records";
import { getMyCustomers, getMySuppliers } from "../../actions/submissions";
import DropDownLocations from "../widgets/dropdownLocations";

class SearchInventoryLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            locationSelected: props.initial && typeof props.initial == "object" ? props.initial : [],
            height: 30,
            items: props.options ? props.options : [],
            filtered: props.options ? props.options : [],
            wasEdited: false,
            openList: false,
        };
    }
    componentDidMount(){
    }
    componentWillReceiveProps(nextProps){
        if(nextProps?.options != this.props.options){
            this.setState({ items: nextProps.options, filtered: nextProps.options, });
        }
        if(nextProps?.initial != this.props.initial){
            this.setState({ locationSelected: nextProps?.initial });
        }
    }
    searchItems(val) {
        const { items } = this.state;
        if(val){
            var filtered = items.filter((item)=> {
                var valToSearch = val.toLowerCase();
                var serach = false;
                if(item?.site?.locationName){
                    if(item.site.locationName.toLowerCase().includes(valToSearch))serach = true;
                }if(item?.zone?.locationName){
                    if(item.zone.locationName.toLowerCase().includes(valToSearch))serach = true;
                }if(item?.aisle?.locationName){
                    if(item.aisle.locationName.toLowerCase().includes(valToSearch))serach = true;
                }if(item?.rackBay?.locationName){
                    if(item.rackBay.locationName.toLowerCase().includes(valToSearch))serach = true;
                }if(item?.shelfLevel?.locationName){
                    if(item.shelfLevel.locationName.toLowerCase().includes(valToSearch))serach = true;
                }if(item?.bin?.locationName){
                    if(item.bin.locationName.toLowerCase().includes(valToSearch))serach = true;
                }
                return serach;
            });
            this.setState({ filtered: filtered, openList: true });
        }else{
            this.setState({ filtered: items });
        } 
    }
    render(){
        const { value, items, wasEdited, filtered, openList } = this.state;
        const { defaultComponent, onSelectItem, placeholder, customers, isShipmentLog, suppliers, width, isRecipe } = this.props;
        return <View>
            <TextInput
                value={value}
                editable={!defaultComponent}
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height == 0) {
                        if (this.props.placeholder == '') {
                            this.setState({ height: 30 })
                        }
                        this.setState({ height: event.nativeEvent.contentSize.height });
                    } else {
                        setTimeout(() => {
                            this.setState({ height: event.nativeEvent.contentSize.height });
                        }, 5)
                    }
                }}
                style={styles.input, {
                    borderColor: "#e7e7e7",
                    borderRadius: 5,
                    borderWidth: 1,
                    color: "#424242",
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: "400",
                    padding: 5,
                    height: this.state.height,
                    fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                    overflow: 'visible',
                }}
                multiline={true}
                placeholder={placeholder}
                placeholderTextColor={'rgb(0, 47, 108)'}
                onChangeText={(val) => {
                    if (val == '' && this.props.placeholder == '') {
                        this.setState({ value: val, height: 30, wasEdited: true });

                    } else {
                        this.setState({ value: val, wasEdited: true });
                    }
                    this.searchItems(val);

                }}
            />
            {
                items?.length > 0 &&
                <DropDownLocations
                  initialOption={this.state.locationSelected}
                  updated={this.state.locationSelected}
                  options={filtered}
                  defaultComponent={defaultComponent}
                  openList={openList}
                  allLabel={"All Locations"}
                  onSelect={(option) => {
                      onSelectItem(option)
                  }}
                  width={width}
                  isRecipe={isRecipe}
                />
            }
            {
                value && items?.length <= 0 && wasEdited && <View>
                    <Text>No locations were found</Text>
                </View>
            }

        </View>
    }
}

export default connect((state, ownProps = {}) => ({
    isLoading: state.forms.getIn(["form", "status"]) === "loading",
    company_id: state.user.get("company_id"),
}), (dispatch, props) => ({
    searchItems: (formid, saveAsTemplate, saveAsForm, callback) =>
        dispatch(searchItems(formid, saveAsTemplate, saveAsForm, callback)),
    getMyCustomers: (data, callback) =>
        dispatch(getMyCustomers(data, callback)), 
    getMySuppliers: (id, page, filter, callback) =>
        dispatch(getMySuppliers(id, page, filter, callback)),
}))(SearchInventoryLocations);

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    row:{
        display: "grid",
        width: "100%",
        fontFamily: "Roboto",
        fontSize: 18
    },
    rowBtn:{
        color: 'rgb(244, 152, 30)',
        border: '2px solid',
        borderRadius: 4
    }
});